import { styled } from 'styled-components'
import CarouselPosts from '../../components/Carousel'
import {
  flex_col,
  grayish,
  graylight,
  grayverylight,
  ralewayFont,
  text_lg,
  white,
} from '../../styles/GlobalStyles'
import { AnimatePresence, motion } from 'framer-motion'
import { t } from 'i18next'
import Text from '../../components/Text'
import { useArticles } from '../../hooks/useIndexedPosts'
import { useEffect, useRef, useState } from 'react'
import useDevice from '../../hooks/useDeviceType'
import { Article } from '../../models/article'

const Topic: React.FC = () => {
  const { articles } = useArticles() // get articles
  // initial topic
  let filteredArticlesMap = new Map(
    Array.from(articles.entries()).filter(([, article]) =>
      article.categories.includes('cate4')
    )
  )
  const [listArticles, setListArticles] =
    useState<Map<string, Article>>(filteredArticlesMap)
  // end initial topic
  const [selectedTopic, setSelectedTopic] = useState(0) // select topic
  const listCategories = ['cate1', 'cate2', 'cate3', 'cate4', 'cate5'] // topics
  const intervalIdRef = useRef<NodeJS.Timer | null | number>(null) // auto change ref
  // change topic
  useEffect(() => {
    filteredArticlesMap = new Map(
      Array.from(articles.entries()).filter(([, article]) =>
        article.categories.includes(listCategories[selectedTopic])
      )
    )
    setListArticles(filteredArticlesMap)
  }, [selectedTopic])
  // auto next topic
  const startInterval = () => {
    intervalIdRef.current = setInterval(() => {
      setSelectedTopic((index) => (index < 4 ? index + 1 : 0))
    }, 5000) // every 5 seconds
  }
  const stopInterval = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current as number)
      intervalIdRef.current = null
    }
  }
  useEffect(() => {
    startInterval()
    return () => stopInterval()
  }, [selectedTopic])

  // stop auto next topic when hover
  const handleHover = (isHover: boolean) => {
    if (isHover) {
      stopInterval()
    } else {
      startInterval()
    }
  }
  // Animation when change topic
  const { isDesktop } = useDevice()
  const topic = {
    animate: {
      opacity: [0, 1],
      x: [20, 0],
      transition: {
        delay: 0.2,
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      x: [0, -20],
      transition: {
        duration: 0.2,
      },
    },
  }
  const posts = {
    animate: {
      opacity: [0, 1],
      x: isDesktop ? [100, 85] : [30, 20],
      transition: {
        delay: 0.2,
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      x: isDesktop ? [85, 65] : [20, 10],
      transition: {
        duration: 0.2,
      },
    },
  }
  return (
    <TopicSection>
      <TopicList>
        {Array.from({ length: 5 }).map((_, index) => (
          <TopicItem
            key={index}
            onClick={() => {
              setSelectedTopic(index)
            }}
          >
            <TopicText
              as={'h5'}
              className={`${index === selectedTopic ? `active` : ``}`}
            >
              {t(`home.topic.menu.${index}.title`)}
            </TopicText>
          </TopicItem>
        ))}
      </TopicList>
      <AnimatePresence mode="wait">
        <TopicContent key={selectedTopic}>
          <TopicDetails variants={topic} animate="animate" exit="exit">
            <TopicTitle as={'h3'}>
              {t(`home.topic.menu.${selectedTopic}.title`)}
            </TopicTitle>
            <TopicDes>{`home.topic.menu.${selectedTopic}.description`}</TopicDes>
          </TopicDetails>
          <PostSection
            key={selectedTopic}
            variants={posts}
            animate="animate"
            exit="exit"
          >
            <CarouselPosts
              setHover={handleHover}
              data={listArticles}
              breakpoints={{
                1280: {
                  slidesPerView: 2.8,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 1.8,
                  spaceBetween: 10,
                },
                320: {
                  slidesPerView: 1.1,
                  spaceBetween: 10,
                },
              }}
            />
          </PostSection>
        </TopicContent>
      </AnimatePresence>
    </TopicSection>
  )
}

export default Topic
const TopicSection = styled.div`
  margin-bottom: 200px;
`
const TopicList = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  margin: 30px 0;
  border-top: 1px solid ${grayverylight};
  border-bottom: 1px solid ${grayverylight};
  @media (max-width: 767px) {
    overflow-x: auto;
    column-gap: 20px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`
const TopicItem = styled.div`
  width: 13%;
  cursor: pointer;
  user-select: none;
  @media (max-width: 767px) {
    width: auto;
  }
`
const TopicText = styled(Text)`
  color: ${graylight};
  transition: all 0.5s;
  overflow: hidden;
  text-overflow: ellipsis;
  &.active {
    color: ${white};
    font-weight: 700;
  }
  &:hover {
    color: ${white};
  }
`
const TopicContent = styled(motion.div)`
  display: flex;
  padding: 10px 0;
  position: relative;
`
const TopicDetails = styled(motion.div)`
  width: 45%;
  ${flex_col}
  row-gap: 30px;
  @media (max-width: 767px) {
    display: none;
  }
`
const TopicTitle = styled(Text)``
const TopicDes = styled(Text)`
  ${ralewayFont}
  color: ${grayish};
  ${text_lg}
  line-height: 1.25;
  transition: all 1s;
`
const PostSection = styled(motion.div)`
  width: 55%;
  @media (max-width: 767px) {
    width: 100%;
  }
`
