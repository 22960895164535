import {
  Container,
  flex_col,
  grayish,
  graylight,
} from '../../styles/GlobalStyles'
import Transition from '../../Transition'
import { styled } from 'styled-components'
import Text from '../../components/Text'
import { useRef } from 'react'
import { motion } from 'framer-motion'
import { CoolComponent } from '../../components/AnimatedShapes/CoolComponent'
import { t } from 'i18next'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useGSAP } from '@gsap/react'
import Topic from './Topic'

//import CarouselProducts from '../../components/Carousel/CarouselProducts'
const Home: React.FC = () => {
  //function scroll animation
  const homeRef = useRef<HTMLDivElement>(null)
  const cool_component = useRef<HTMLDivElement>(null)
  gsap.registerPlugin(useGSAP, ScrollTrigger)
  useGSAP(
    () => {
      if (homeRef.current) {
        const { offsetWidth, offsetHeight } = homeRef.current
        const mm = gsap.matchMedia(),
          breakPoint = [767, 1024]
        mm.add(
          {
            isMobile: `(max-width: ${breakPoint[0]}px)`,
            isTablet: `(max-width: ${breakPoint[1]}px) and (min-width:${breakPoint[0] + 1}px)`,
            isDesktop: `(min-width: ${breakPoint[1] + 1}px)`,
          },
          (context) => {
            if (context.conditions) {
              const { isMobile, isTablet } = context.conditions
              if (isMobile) {
                gsap.set('.vr', {
                  width: 500,
                  height: 500,
                })
              }
              gsap.set(cool_component.current, {
                x: isMobile
                  ? 0
                  : isTablet
                    ? offsetWidth - offsetWidth * 0.55 + 60
                    : offsetWidth - offsetWidth * 0.55,
                y: 0,
              })
              if (!isMobile) {
                const tl = gsap.timeline({
                  scrollTrigger: {
                    trigger: homeRef.current,
                    start: 'top top',
                    end: 'bottom center',
                    scrub: 5,
                  },
                })
                tl.to(cool_component.current, {
                  x: -offsetWidth * 0.55,
                  y: offsetHeight * 0.3,
                  duration: 2,
                  ease: 'power1.out',
                })
                  .to('.vr', { width: 150, height: 150, duration: 1 }, '<')
                  .to(cool_component.current, {
                    x: (-offsetWidth * 0.45) / 2,
                    y: offsetHeight * 0.75,
                    duration: 1,
                    ease: 'power1.out',
                  })
                  .to(cool_component.current, {
                    x: offsetWidth - offsetWidth * 0.55,
                    y: offsetHeight * 0.65,
                    duration: 2,
                    ease: 'power1.out',
                  })
                  .to(
                    '.vr',
                    {
                      width: 320,
                      height: 320,
                      duration: 1,
                      ease: 'power1.out',
                    },
                    '<'
                  )
              }
            }
          }
        )
      }
    },
    { scope: homeRef }
  )
  //end function
  return (
    <Transition>
      <HomeContainer ref={homeRef}>
        <AnimationComponent ref={cool_component}>
          <CoolComponent />
        </AnimationComponent>
        <HeroSection>
          <HeroSectionLeft>
            <HeroTitle>{'home.hero.title'}</HeroTitle>
            <HeroSubtitle as={'h1'}>{t('home.hero.sub-title')}</HeroSubtitle>
            <HeroTagline>{'home.hero.tagline'}</HeroTagline>
          </HeroSectionLeft>
        </HeroSection>
        <Topic />
      </HomeContainer>
    </Transition>
  )
}

export default Home

const HomeContainer = styled(Container)`
  position: relative;
`
const HeroSection = styled.div`
  width: 55%;
  padding-top: 50px;
  padding-bottom: 100px;
  margin-bottom: 150px;
  @media (max-width: 767px) {
    width: 100%;
  }
`
const HeroSectionLeft = styled.div`
  padding-top: 50px;
  width: 100%;
  ${flex_col}
  row-gap: 10px;
`
const HeroTitle = styled(Text)`
  font-size: 30px;
  letter-spacing: 0.1em;
  color: ${grayish};
`
const HeroSubtitle = styled(Text)`
  width: 90%;
  letter-spacing: 5px;
  font-size: 40px;
`
const HeroTagline = styled(Text)`
  letter-spacing: 0.1em;
  line-height: 1.4;
  font-size: 20px;
  color: ${graylight};
`
const AnimationComponent = styled(motion.div)`
  position: absolute;
  height: 400px;
  width: 45%;
  top: 100px;
  @media (max-width: 767px) {
    position: absolute;
    width: 500px;
    height: 500px;
    right: -240px;
    top: -140px;
    z-index: -1;
  }
`
