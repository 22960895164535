import { useState } from 'react'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'

const Player: React.FC = () => {
  const playlist = [
    { src: '/assets/mp3/music.mp3' },
    { src: '/assets/mp3/music1.mp3' },
    { src: '/assets/mp3/music2.mp3' },
  ]
  const [currentTrack, setCurrentTrack] = useState(0)
  const handleClickNext = () => {
    setCurrentTrack((currentTrack) =>
      currentTrack < playlist.length - 1 ? currentTrack + 1 : 0
    )
  }

  const handleEnd = () => {
    setCurrentTrack((currentTrack) =>
      currentTrack < playlist.length - 1 ? currentTrack + 1 : 0
    )
  }
  return (
    <AudioPlayer
      autoPlay
      src={playlist[currentTrack].src}
      showSkipControls
      volume={0.3}
      onClickNext={handleClickNext}
      onEnded={handleEnd}
      onPlay={() => console.log('onPlay')}
    />
  )
}
export default Player
