import Markdown from 'react-markdown'
import { Container, LeftSection, UnderLine } from '../../styles/GlobalStyles'
import Transition from '../../Transition'
import { useProjects } from '../../hooks/useIndexedProjects'
import { Project } from '../../models/project'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { PostCategory, PostTags, PostHeading, PostTitle } from '../post'
import Text from '../../components/Text'

const ProjectMain: React.FC = () => {
  const { slug } = useParams<{ slug: string }>()
  const { projects } = useProjects()
  const [project, setProject] = useState<Project | undefined>()
  useEffect(() => {
    setProject(slug ? projects.get(slug) : undefined)
  }, [slug, projects])
  return (
    <Transition>
      <Container>
        {project ? (
          <LeftSection>
            <PostHeading>
              <PostTitle as={'h2'}>{project.title}</PostTitle>
              <PostCategory>{project.categories.join(', ')}</PostCategory>
              <PostTags>{project.tags.join(', ')}</PostTags>
            </PostHeading>
            <UnderLine />
            <Markdown>{project.content}</Markdown>
            <UnderLine />
          </LeftSection>
        ) : (
          <Text>Project not found</Text>
        )}
      </Container>
    </Transition>
  )
}
export default ProjectMain
