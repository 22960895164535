import { useFoodsApi } from './useFoodsApi'
import { BrandedFoodItem, SearchResult } from '../api/foods'
import { useState } from 'react'

const useNutritionalTool = () => {
  const foodsApi = useFoodsApi()
  const [isLoading, setIsLoading] = useState(false)

  const handleSearchFood = async (keyword: string) => {
    try {
      const response = await foodsApi.getFoodsSearch(keyword, ['Branded'])
      const data: SearchResult = response.data as SearchResult
      return data.foods
    } catch (e) {
      console.log(e)
    }
  }
  const handleSelectedFood = async (foodId: string) => {
    try {
      const response = await foodsApi.getFood(foodId)
      const data: BrandedFoodItem = response.data as BrandedFoodItem
      console.log(data)
      if (data.labelNutrients) {
        return {
          id: data.fdcId,
          description: data.description,
          calories: data.labelNutrients.calories?.value,
          protein: data.labelNutrients.protein?.value,
          carbohydrates: data.labelNutrients.carbohydrates?.value,
          fat: data.labelNutrients.fat?.value,
          servingSize: data.servingSize,
          servingUnit: data.servingSizeUnit || 'no data',
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  return {
    isLoading,
    setIsLoading,
    handleSearchFood,
    handleSelectedFood,
  }
}
export default useNutritionalTool
