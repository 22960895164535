import { styled } from 'styled-components'
import {
  black,
  center,
  Container,
  flex_col,
  graylight,
  grayverylight,
  LeftSection,
  primary,
  ralewayFont,
  RightSection,
  text_2xl,
  text_lg,
  text_xs,
  UnderLine,
} from '../../../styles/GlobalStyles'
import Transition from '../../../Transition'
import { useEffect, useMemo, useRef, useState } from 'react'
import useClickOutside from '../../../hooks/handleClickOutside'
import { SearchResultFood } from '../../../api/foods'
import { RotatingLines } from 'react-loader-spinner'
import { motion } from 'framer-motion'
import Text from '../../../components/Text'
import { SelectedFood } from '../../../models/food'
import useNutritionalTool from '../../../hooks/useNutritionalTool'
import { useDebouncedCallback } from 'use-debounce'
import DoughnutChart from '../../../components/DoughnutChart'
import { PageHeading } from '../../lifestyle'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { FaRegTrashAlt } from 'react-icons/fa'
import useMealPlans from '../../../hooks/useMealPlans'

interface macrosInterface {
  baseTargetCalories: number
  targetCalories: number
  targetCaloriesByCalculationFocus: number
  macrosDistribution: {
    CARBOHYDRATES: number
    FATS: number
    PROTEIN: number
  }
  macrosDistributionByCalculationFocus: {
    CARBOHYDRATES: number
    FATS: number
    PROTEIN: number
  }
}
const NutritionalValue = () => {
  const [foodData, setFoodData] = useState<SearchResultFood[] | undefined>([])
  const [selectedFood, setSelectedFood] = useState<SelectedFood | undefined>(
    undefined
  )
  const [foodInput, setfoodInput] = useState<string>('')
  const [openResult, setOpenResult] = useState<boolean>(false)
  const [listNutrition, setListNutrition] = useState<number[]>([])
  const [feedback, setFeedback] = useState<string>('')
  const { isLoading, handleSearchFood, handleSelectedFood, setIsLoading } =
    useNutritionalTool()
  const resultRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [macrosCalculation, setMacrosCalculation] = useState<macrosInterface>()
  const [servingSize, setServingSize] = useState<string>('')
  const [isMissingGoal, setIsMissingGoal] = useState(false)
  const { listMealPlans, addMealPlans, saveMealPlans, deleteMeal } =
    useMealPlans()
  const navigate = useNavigate()

  const isCalculationFocus = useMemo(
    () =>
      macrosCalculation?.targetCalories !==
      macrosCalculation?.targetCaloriesByCalculationFocus,
    [macrosCalculation]
  )
  useEffect(() => {
    setFeedback('')
    const calculateMacros = localStorage.getItem('calculateMacros')
    if (calculateMacros) {
      setMacrosCalculation(JSON.parse(calculateMacros))
    }
  }, [])
  useClickOutside(resultRef, () => {
    if (inputRef.current && !inputRef.current.contains(event?.target as Node)) {
      setOpenResult(false)
      onSearchFood.flush()
    }
  })

  const onSearchFood = useDebouncedCallback((keyword: string) => {
    handleSearchFood(keyword).then((data) => {
      setFoodData(data)
    })
  }, 1000)
  const onSelectedFood = (id: string) => {
    handleSelectedFood(id).then((data) => {
      setSelectedFood(data)
      if (data?.servingSize) setServingSize(data?.servingSize?.toString())
      if (data) {
        const carbs = data.carbohydrates ? data.carbohydrates : 0
        const proteins = data.protein ? data.protein : 0
        const fats = data.fat ? data.fat : 0
        const totalCalories = carbs * 4 + proteins * 4 + fats * 9
        setListNutrition([
          ((carbs * 4) / totalCalories) * 100,
          ((proteins * 4) / totalCalories) * 100,
          ((fats * 9) / totalCalories) * 100,
        ])
      }
    })
  }
  useEffect(() => {
    const totalCalories = listMealPlans.reduce((accumulator, meal) => {
      return accumulator + (meal.calories ? meal.calories : 1)
    }, 0)
    if (macrosCalculation) {
      const targetCalories = isCalculationFocus
        ? macrosCalculation.targetCaloriesByCalculationFocus.toFixed(2)
        : macrosCalculation.targetCalories.toFixed(2)
      setIsMissingGoal(totalCalories < parseFloat(targetCalories))
    }
  }, [listMealPlans])
  useEffect(() => {
    setIsLoading(onSearchFood.isPending())
  }, [onSearchFood.isPending()])

  return (
    <Transition>
      <NutritionContainer>
        <LeftSection>
          <PageHeading>{t('tools.nutritional-value.title.1')}</PageHeading>
          <SearchFood>
            <Input
              value={foodInput}
              ref={inputRef}
              placeholder={`${t('tools.nutritional-value.common.placeholder.foodName')}`}
              onFocus={() => setOpenResult(true)}
              onChange={(e) => {
                setfoodInput(e.target.value)
                const keyword = e.target.value
                onSearchFood(keyword)
              }}
            />
            <SearchList ref={resultRef} className={`${openResult && 'open'}`}>
              {isLoading ? (
                <LoadingSearchQuery
                  animate={{
                    opacity: [0, 1],
                    transition: {
                      delay: 0.5,
                      duration: 0.3,
                    },
                  }}
                >
                  <RotatingLines
                    visible={true}
                    width="30"
                    strokeColor="rgb(255, 255, 255)"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                  />
                </LoadingSearchQuery>
              ) : foodData && foodData.length != 0 ? (
                foodData.slice(0, 8).map((food, index) => (
                  <FoodResult
                    key={index}
                    onClick={() => {
                      setOpenResult(!openResult)
                      setfoodInput('')
                      onSelectedFood(food.fdcId.toString())
                    }}
                  >
                    <FoodDescription>{food.description}</FoodDescription>
                    <FoodBrandOwner>{food.brandOwner}</FoodBrandOwner>
                  </FoodResult>
                ))
              ) : (
                <Text>{'common.no-matches'}</Text>
              )}
            </SearchList>
          </SearchFood>
          <FoodName>
            <Label>
              {t('tools.nutritional-value.nutrition-info.description')}
            </Label>
            {selectedFood ? (
              <span>{`${selectedFood.description} | ${selectedFood.calories} calories in ${selectedFood.servingSize}  grams`}</span>
            ) : null}
          </FoodName>
          <UnderLine />
          <Label>
            {t('tools.nutritional-value.nutrition-info.composition')}
          </Label>
          {selectedFood ? (
            <Composition>
              <CompositionValue>
                <DoughnutChart
                  id={`chart${selectedFood.id}`}
                  values={listNutrition}
                  label={['carbs', 'protein', 'fats']}
                />
              </CompositionValue>
            </Composition>
          ) : null}
          <UnderLine />
          <Label>
            {t('tools.nutritional-value.nutrition-info.nutritional.label')}
          </Label>
          {selectedFood ? (
            <NutritionalInfomation>
              <NutritionValue>
                <NutritionResult $bgColor="#d9f8fa">
                  {selectedFood.calories}{' '}
                  {t('tools.nutritional-value.common.unit.kcal')}
                </NutritionResult>
                <NutritionLabel>
                  {t('tools.nutritional-value.nutrition-info.nutritional.1')}
                </NutritionLabel>
              </NutritionValue>
              <NutritionValue>
                <NutritionResult $bgColor="#f8f0bf">
                  {selectedFood.carbohydrates}
                  {t('tools.nutritional-value.common.unit.g')}
                </NutritionResult>
                <NutritionLabel>
                  {t('tools.nutritional-value.nutrition-info.nutritional.2')}
                </NutritionLabel>
              </NutritionValue>
              <NutritionValue>
                <NutritionResult $bgColor="#fddabe">
                  {selectedFood.protein}{' '}
                  {t('tools.nutritional-value.common.unit.g')}
                </NutritionResult>
                <NutritionLabel>
                  {t('tools.nutritional-value.nutrition-info.nutritional.3')}
                </NutritionLabel>
              </NutritionValue>
              <NutritionValue>
                <NutritionResult $bgColor="#e8fdda">
                  {selectedFood.fat}
                  {t('tools.nutritional-value.common.unit.g')}
                </NutritionResult>
                <NutritionLabel>
                  {t('tools.nutritional-value.nutrition-info.nutritional.4')}
                </NutritionLabel>
              </NutritionValue>
            </NutritionalInfomation>
          ) : null}
          <UnderLine />
          <AddToPlans>
            <Input
              $width="200px"
              value={servingSize}
              placeholder={`${t('tools.nutritional-value.common.placeholder.servingSize')}`}
              onChange={(e) => {
                setServingSize(e.target.value)
              }}
            />
            <Button onClick={() => addMealPlans(selectedFood, servingSize)}>
              {t('tools.nutritional-value.nutrition-info.add-to-plans.button')}
            </Button>
          </AddToPlans>
        </LeftSection>
        <RightSection>
          <PageHeading>{t('tools.nutritional-value.title.2')}</PageHeading>
          {t('tools.nutritional-value.target-calories.goal.label')}:{' '}
          <DailyTarget>
            {macrosCalculation && (
              <>
                <TargetValue>
                  {t('tools.nutritional-value.target-calories.goal.calories')}
                  {': '}
                  {isCalculationFocus
                    ? macrosCalculation.targetCaloriesByCalculationFocus
                    : macrosCalculation.targetCalories.toFixed(2)}
                </TargetValue>
                <TargetValue>
                  {t('tools.nutritional-value.target-calories.goal.proteins')}
                  {': '}
                  {isCalculationFocus
                    ? macrosCalculation.macrosDistributionByCalculationFocus
                        .PROTEIN
                    : macrosCalculation.macrosDistribution.PROTEIN.toFixed(2)}
                </TargetValue>
                <TargetValue>
                  {t('tools.nutritional-value.target-calories.goal.carbs')}
                  {': '}
                  {isCalculationFocus
                    ? macrosCalculation.macrosDistributionByCalculationFocus
                        .CARBOHYDRATES
                    : macrosCalculation.macrosDistribution.CARBOHYDRATES.toFixed(
                        2
                      )}
                </TargetValue>
                <TargetValue>
                  {t('tools.nutritional-value.target-calories.goal.fats')}
                  {': '}
                  {isCalculationFocus
                    ? macrosCalculation.macrosDistributionByCalculationFocus
                        .FATS
                    : macrosCalculation.macrosDistribution.FATS.toFixed(2)}
                </TargetValue>
              </>
            )}

            <Button
              onClick={() => {
                navigate('/tools/macros-calculator')
              }}
            >
              {macrosCalculation
                ? t('tools.nutritional-value.target-calories.goal.modify')
                : t('tools.nutritional-value.target-calories.goal.setting')}
            </Button>
          </DailyTarget>
          <FeedBack>{feedback}</FeedBack>
          <MealPlans>
            <MealPlansLabel>
              {t('tools.nutritional-value.target-calories.mealplans.label')}
            </MealPlansLabel>
            {listMealPlans.map((food, index) => (
              <Meal key={index}>
                <MealNumber>{index + 1}</MealNumber>
                <MealInfo>
                  <MealDes>
                    {food.description} | {food.calories} calories in{' '}
                    {food.servingSize}
                    {food.servingUnit}
                  </MealDes>
                  <MealNutrition>
                    proteins:{food.protein}g | carb:{food.carbohydrates}g |
                    fats:{food.fat}g
                  </MealNutrition>
                </MealInfo>
                <MealAction>
                  <MealButton $bgColor="red" onClick={() => deleteMeal(index)}>
                    <FaRegTrashAlt />
                  </MealButton>
                </MealAction>
              </Meal>
            ))}
            <TotalNutrition>
              {t(
                'tools.nutritional-value.target-calories.mealplans.total.label'
              )}
              <TotalCalories $color={`${!isMissingGoal ? 'red' : ''} `}>
                calories:{' '}
                {listMealPlans.reduce((accumulator, meal) => {
                  return accumulator + (meal.calories ? meal.calories : 1)
                }, 0)}
              </TotalCalories>
              <TotalCarbs>
                carbs:{' '}
                {listMealPlans.reduce((accumulator, meal) => {
                  return (
                    accumulator + (meal.carbohydrates ? meal.carbohydrates : 1)
                  )
                }, 0)}
              </TotalCarbs>
              <TotalProteins>
                proteins:{' '}
                {listMealPlans.reduce((accumulator, meal) => {
                  return accumulator + (meal.protein ? meal.protein : 1)
                }, 0)}
              </TotalProteins>
              <TotalFats>
                fats:{' '}
                {listMealPlans.reduce((accumulator, meal) => {
                  return accumulator + (meal.fat ? meal.fat : 1)
                }, 0)}
              </TotalFats>
            </TotalNutrition>
            <Button onClick={saveMealPlans}>
              {t(
                'tools.nutritional-value.target-calories.mealplans.save-plans'
              )}
            </Button>
          </MealPlans>
        </RightSection>
      </NutritionContainer>
    </Transition>
  )
}
export default NutritionalValue

const NutritionContainer = styled(Container)`
  display: flex;
  column-gap: 2%;
`
const SearchFood = styled.div`
  position: relative;
`
const SearchList = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  padding: 10px;
  width: 100%;
  z-index: 10;
  opacity: 0;
  background-color: rgba(31, 31, 31, 1);
  visibility: hidden;
  transition: all 0.5s ease-in-out;
  &.open {
    opacity: 1;
    visibility: visible;
  }
`
const LoadingSearchQuery = styled(motion.div)`
  ${center}
`
const FoodResult = styled.div`
  padding: 20px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  ${flex_col}
  &:hover {
    background-color: ${grayverylight};
  }
`
const Input = styled.input<{ $width?: string }>`
  width: ${(props) => (props.$width ? props.$width : '100%')};
  position: relative;
  padding: 16px 20px;
  caret-color: white;
  color: ${graylight};
  background: rgba(31, 31, 31, 1);
  border: none;
  border-bottom: 1px solid transparent;
  outline: none;
`
const FoodName = styled.span`
  ${text_2xl}
  ${flex_col}
  row-gap: 10px;
  margin-top: 20px;
`
const Label = styled.span`
  color: ${graylight};
  ${text_lg}
`
const FoodDescription = styled.span``
const FoodBrandOwner = styled.span`
  color: ${graylight};
  ${text_xs};
`
const Composition = styled.div`
  ${center}
`
const CompositionValue = styled.div``

const NutritionalInfomation = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  justify-items: center;
`
const NutritionValue = styled.div`
  width: 100%;
  text-align: center;
  padding: 0 20px;
  row-gap: 5px;
  ${flex_col}
`
const NutritionResult = styled.div<{ $bgColor?: string }>`
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  background: ${(props) => (props.$bgColor ? props.$bgColor : 'transparent')};
  color: ${black};
`
const NutritionLabel = styled.div`
  color: ${graylight};
`
const DailyTarget = styled.div`
  gap: 10px;
  ${flex_col}
  ${ralewayFont}
`
const TargetValue = styled.div``
const FeedBack = styled.div``
const MealPlans = styled.div`
  margin-top: 20px;
  gap: 10px;
  ${flex_col}
  ${ralewayFont}
`
const MealPlansLabel = styled.div`
  ${text_2xl}
`
const Meal = styled.div`
  display: flex;
`
const MealDes = styled.div``
const MealNutrition = styled.div``
const AddToPlans = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
`
const Button = styled.div<{ $width?: string }>`
  width: ${(props) => (props.$width ? props.$width : '150px')};
  background-color: ${graylight};
  transition: all 0.5s ease-in-out;
  padding: 10px;
  cursor: pointer;
  ${center}
  &:hover {
    background-color: ${primary};
  }
`
const MealInfo = styled.div`
  width: 100%;
`
const MealAction = styled.div`
  display: flex;
  gap: 5px;
`
const MealNumber = styled.span`
  padding: 10px;
  ${center}
  ${text_lg}
`
const MealButton = styled.div<{ $bgColor?: string }>`
  background-color: ${(props) =>
    props.$bgColor ? props.$bgColor : 'transparent'};
  padding: 10px;
  border-radius: 10px;
  opacity: 0.8;
  transition: opacity 0.5s;
  cursor: pointer;
  ${center}
  &:hover {
    opacity: 1;
  }
`
const TotalNutrition = styled.div``
const TotalCalories = styled.div<{ $color?: string }>`
  color: ${(props) => (props.$color ? props.$color : 'inherit')};
`
const TotalProteins = styled.div``
const TotalCarbs = styled.div``
const TotalFats = styled.div``
