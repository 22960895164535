import { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import {
  FaGithub,
  FaTelegram,
  FaLinkedin,
  FaInstagram,
  FaBars,
} from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import Text from '../Text'
import {
  Button,
  center,
  Container,
  flex_col,
  graylight,
  grayverylight,
  growHover,
  white,
} from '../../styles/GlobalStyles'
import Logo from '../../assets/svg/logo'
import useDisabledScroll from '../../hooks/useDisableScroll'
import Search from './Search'
import { PATH } from '../../constants/PathConstants'
import { motion } from 'framer-motion'
import ArrowDropDownIcon from '../../assets/svg/DropDownIcon'

const Navbar: React.FC = () => {
  const navItem = [
    PATH.ABOUT,
    PATH.PROJECTS,
    PATH.LIFESTYLE,
    PATH.THOUGHTS,
    PATH.TOOLS,
  ]
  const [isOpenTools, setIsOpenTools] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [toggleMenu, setToggleMenu] = useState('hidden')
  const { setIsScroll } = useDisabledScroll()
  // Toggle MenuMobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const toggleMobileMenu = () => {
    if (isMobile) {
      setToggleMenu(toggleMenu === 'hidden' ? 'visible' : 'hidden')
      setIsScroll(toggleMenu !== 'hidden')
    }
  }
  // End Toggle MenuMobile
  // Socials
  const socials = [
    { icon: FaInstagram, url: 'https://instagram.com/leonacostaok' },
    { icon: FaGithub, url: 'https://github.com/leonacostaok' },
    { icon: FaTelegram, url: 'https://t.me/leonacostaok' },
    { icon: FaLinkedin, url: 'https://linkedin.com/in/leonacostaok' },
  ]

  return (
    <>
      <NavbarWrapper>
        <NavPage to={''}>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
        </NavPage>
        <Search />
        <ItemWrapper className={toggleMenu}>
          <CloseButton onClick={toggleMobileMenu}>
            <MdClose />
          </CloseButton>
          {navItem.map((item, index) => (
            <Fragment key={index}>
              {index === 4 ? (
                <ToolsMenu
                  initial={false}
                  animate={isOpenTools ? 'open' : 'closed'}
                >
                  <ToolsButton
                    as={motion.div}
                    onClick={() => setIsOpenTools(!isOpenTools)}
                  >
                    <Text>{`header.navigation.${index}.label`}</Text>
                    <DropDownIcon
                      variants={{
                        open: { rotate: 180 },
                        closed: { rotate: 0 },
                      }}
                      transition={{ duration: 0.2 }}
                      style={{ originY: 0.55 }}
                    >
                      <ArrowDropDownIcon />
                    </DropDownIcon>
                  </ToolsButton>
                  <ListTools
                    variants={{
                      open: {
                        clipPath: 'inset(0% 0% 0% 0% round 10px)',
                        transition: {
                          type: 'spring',
                          bounce: 0,
                          duration: 0.7,
                          delayChildren: 0.3,
                          staggerChildren: 0.05,
                        },
                      },
                      closed: {
                        clipPath: 'inset(10% 50% 90% 50% round 10px)',
                        transition: {
                          type: 'spring',
                          bounce: 0,
                          duration: 0.3,
                        },
                      },
                    }}
                    style={{ pointerEvents: isOpenTools ? 'auto' : 'none' }}
                  >
                    {Object.values(item).map((tool, toolIndex) => (
                      <NavPage
                        to={`${tool}`}
                        key={toolIndex}
                        onClick={() => setIsOpenTools(!isOpenTools)}
                      >
                        <Tool>
                          <Text>{`header.navigation.${index}.${toolIndex}.label`}</Text>
                        </Tool>
                      </NavPage>
                    ))}
                  </ListTools>
                </ToolsMenu>
              ) : (
                <NavPage
                  className={({ isActive }) => (isActive ? 'active' : '')}
                  onClick={toggleMobileMenu}
                  key={index}
                  to={`/${item}`}
                >
                  <NavItem>
                    <Text>{`header.navigation.${index}.label`}</Text>
                  </NavItem>
                </NavPage>
              )}
            </Fragment>
          ))}
          <SocialsSection>
            {socials.map((item, index) => (
              <LinkSocial
                key={index}
                href={item.url}
                target="_blank"
                rel="noreferrer"
              >
                <item.icon size={18} />
              </LinkSocial>
            ))}
          </SocialsSection>
        </ItemWrapper>
        <MobileBar onClick={toggleMobileMenu}>
          <FaBars />
        </MobileBar>
      </NavbarWrapper>
    </>
  )
}
export default Navbar

const LinkSocial = styled.a`
  ${growHover}
  ${center}
`
const MobileBar = styled(Button)`
  ${center};
  font-size: 30px;
  color: ${graylight};
  display: none;
  &:hover {
    color: ${white};
  }
  @media (max-width: 768px) {
    display: flex;
  }
`
const CloseButton = styled(MobileBar)`
  position: absolute;
  font-size: 40px;
  top: 20px;
  right: 20px;
  color: ${graylight};
  z-index: 11;
  &:hover {
    color: ${white};
  }
`
const NavItem = styled(motion.div)`
  box-sizing: border-box;
  ${center};
  color: ${graylight};
  padding: 10px 20px;
  user-select: none;
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 10px 6.5px;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
`
const NavPage = styled(NavLink)`
  text-decoration: none;
  ${NavItem} {
    color: ${graylight};
    ${growHover}
  }
  &.active ${NavItem} {
    color: ${white};
  }
`
const LogoWrapper = styled.div`
  user-select: none;
`
const NavbarWrapper = styled(Container)`
  ${center};
  flex-direction: row;
  position: relative;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  @media (max-width: 500px) {
    justify-content: space-between;
  }
`
const ItemWrapper = styled.div`
  ${center};
  transform: translateX(0px);
  @media (max-width: 768px) {
    &.hidden {
      transform: translateX(100%);
      display: none;
    }
    &.vivisible {
      transform: translateX(0px);
    }
    transition: all 0.5s;
    flex-direction: column;
    justify-content: end;
    align-items: start;
    row-gap: 20px;
    width: 100vw;
    height: 100vh;
    background: black;
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    font-size: 36px;
    z-index: 10;
  }
`
const SocialsSection = styled(NavItem)`
  column-gap: 5px;
  ${center};
  @media (max-width: 768px) {
    padding-top: 50px;
  }
`
const ToolsMenu = styled(motion.nav)`
  position: relative;
`
const ToolsButton = styled(NavItem)`
  cursor: pointer;
  ${growHover}
  &:hover {
    path {
      fill: ${white};
      transition: all 0.3s ease-in;
      transform: scale(1.1);
    }
  }
`
const DropDownIcon = styled(motion.div)`
  margin-left: 2px;
  path {
    fill: ${graylight};
  }
`
const ListTools = styled(motion.ul)`
  position: absolute;
  top: calc(100% + 10px);
  left: -50px;
  ${flex_col};
  background-color: ${grayverylight};
`
const Tool = styled(NavItem)`
  width: max-content;
  padding: 20px;
  &:hover {
    transform: none !important;
  }
`
