import Markdown from 'react-markdown'
import { styled } from 'styled-components'
import {
  grayish,
  grayveryverylight,
  primary,
  ralewayFont,
  white,
} from '../../styles/GlobalStyles'
import { Article } from '../../models/article'
import { androidstudio, CopyBlock } from 'react-code-blocks'
import { defaultMapOptions, Map } from '../Map'
import { parseMapOptions } from '../../utils/MapUtils'
import { Product } from '../../models/product'
import CarouselProducts from '../Carousel/CarouselProducts'
import Player from '../Player'

export const MarkdownElement = ({
  article,
  products,
}: {
  article: Article
  products?: Map<string, Product>
}) => {
  return (
    <MarkdownContainer
      urlTransform={(uri) => {
        return uri.startsWith('images/')
          ? `${window.location.origin}/assets/${uri.substring(7, uri.length)}`
          : uri
      }}
      components={{
        code(props) {
          const { children, className } = props
          const match = /language-(\w+)/.exec(className || '')
          const isMap = match && match.length > 1 && match[1] === 'MapHere'
          const isCarousel =
            match && match.length > 1 && match[1] === 'CarouselHere'
          const isPlayer =
            match && match.length > 1 && match[1] === 'PlayerHere'
          const mapOptions = isMap
            ? parseMapOptions(String(children))
            : defaultMapOptions
          return isMap ? (
            <Map slug={article.slug} options={mapOptions} />
          ) : isCarousel && products ? (
            <CarouselProducts slidesPerView={4} data={products} />
          ) : isPlayer ? (
            <Player />
          ) : (
            <CopyBlock
              codeBlock={true}
              theme={androidstudio}
              text={String(children)}
              language={match && match.length > 1 && match[1] ? match[1] : ''}
              showLineNumbers={true}
            />
          )
        },
        img({ className, ...rest }) {
          return <img width={400} className={className} {...rest} />
        },
      }}
    >
      {article.content}
    </MarkdownContainer>
  )
}

const MarkdownContainer = styled(Markdown)`
  // heading 2
  h2 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 30px;
  }

  // heading 3
  h3 {
    margin-top: 15px;
    margin-bottom: 10px;
    font-weight: 100;
    font-size: 22px;
    line-height: 1.4;
    color: ${grayish};
  }

  // normal text
  p {
    font-weight: 100;
    font-size: 16px;
    line-height: 1.4;
    color: ${grayish};
    ${ralewayFont}
  }

  // quote
  p:has(> strong) {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px 10px 20px 30px;
    background-color: ${grayveryverylight};
    border-left: solid 2px ${primary};

    strong {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.4;
    }

    &:before {
      content: open-quote;
    }
  }

  // lists
  ol li {
    margin: 10px 0;
    color: ${grayish};
    ${ralewayFont}
    strong {
      color: ${white};
      font-weight: 500;
      font-size: 16px;
      line-height: 1.4;
    }
  }

  // images
  img {
    margin: 30px 0;
  }
`
