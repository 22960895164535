import { t } from 'i18next'
import CarouselPosts from '../../components/Carousel'
import { UnderLine } from '../../styles/GlobalStyles'
import Transition from '../../Transition'
import {
  PageContainer,
  HeroSection,
  PageHeading,
  PageDescription,
  SimilarPostsSection,
  SectionPost,
  SectionTitle,
} from '../lifestyle'
import { useArticles } from '../../hooks/useIndexedPosts'

const Thoughts: React.FC = () => {
  const { articles } = useArticles()

  return (
    <Transition>
      <PageContainer>
        <HeroSection>
          <PageHeading as={'h2'}>{t('lifestyle.heading')}</PageHeading>
          <PageDescription>{'lifestyle.description'}</PageDescription>
          <UnderLine />
        </HeroSection>
        <SimilarPostsSection>
          <SectionPost>
            <SectionTitle as={'h2'}>subcategory xyz</SectionTitle>
            <CarouselPosts
              data={articles}
              breakpoints={{
                1280: {
                  slidesPerView: 5,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 3.5,
                  spaceBetween: 10,
                },
                320: {
                  slidesPerView: 1.5,
                  spaceBetween: 10,
                },
              }}
            ></CarouselPosts>
          </SectionPost>
          <SectionPost>
            <SectionTitle as={'h2'}>subcag keywordrap</SectionTitle>
            <CarouselPosts
              data={articles}
              breakpoints={{
                1280: {
                  slidesPerView: 5,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 3.5,
                  spaceBetween: 10,
                },
                320: {
                  slidesPerView: 1.5,
                  spaceBetween: 10,
                },
              }}
            ></CarouselPosts>
          </SectionPost>
        </SimilarPostsSection>
      </PageContainer>
    </Transition>
  )
}
export default Thoughts
