import styled, { createGlobalStyle, css } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  html{
    font-size: 100%;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: ${({ theme }) => theme.fonts.orbitron};
    color: ${({ theme }) => theme.colors.white};
    background-image: linear-gradient(to bottom right, black, #1f1f1f);
    overflow-x: hidden;
  }

  * {
    box-sizing: border-box;
    text-transform: lowercase;

  }
  h2{
    font-size: 30px;
    line-height: 1.5;
    letter-spacing: 0.12em;
    font-weight: normal;
  }
  h5{
    font-size: 20px;
    line-height: 1.4;
    font-weight: normal;
  }
  h3{
    font-size: 30px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  dl,
  dd {
    padding: 0;
    margin: 0;

  }
  ul[role="list"],
  ol[role="list"] {
    list-style: none;
  }
  li {
    list-style-type: none;
  }
  html:focus-within {
    scroll-behavior: smooth;
  }
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }
  a{
    text-decoration: none;
    color: unset;
  }
  img,
  picture {
    max-width: 100%;
    display: block;
  }
  input,
  button,
  textarea,
  select {
    font: inherit;
  }
  input:focus::placeholder {
        color: transparent; 
    }
  `
export const primary = css`
  ${({ theme }) => theme.colors.primary}
`
export const white = css`
  ${({ theme }) => theme.colors.white}
`
export const black = css`
  ${({ theme }) => theme.colors.black}
`
export const grayish = css`
  ${({ theme }) => theme.colors.grayish}
`
export const graylight = css`
  ${({ theme }) => theme.colors.graylight}
`
export const grayverylight = css`
  ${({ theme }) => theme.colors.grayverylight}
`

export const grayveryverylight = css`
  ${({ theme }) => theme.colors.grayveryverylight}
`
export const ralewayFont = css`
  font-family: ${({ theme }) => theme.fonts.raleway};
`
export const orbitronFont = css`
  font-family: ${({ theme }) => theme.fonts.raleway};
`
export const text_xs = css`
  font-size: 12px;
`
export const text_sm = css`
  font-size: 14px;
`
export const text_base = css`
  font-size: 16px;
`
export const text_lg = css`
  font-size: 18px;
`
export const text_xl = css`
  font-size: 20px;
`
export const text_2xl = css`
  font-size: 22px;
`
export const growHover = css`
  &:hover {
    color: ${white};
    transition: all 0.3s ease-in;
    transform: scale(1.1);
  }
`
export const UnderLine = styled.div`
  display: flex;
  width: 100%;
  padding: 20px 0;
  &::before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: ${grayverylight};
  }
`

export const center = css`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const flex_col = css`
  display: flex;
  flex-direction: column;
`
export const Container = styled.div`
  z-index: 1;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 50px 100px 50px 100px;
  letter-spacing: 0.08em;
  @media (max-width: 1024px) {
    padding: 50px 30px;
  }
`
export const LeftSection = styled.div`
  width: 60%;
  row-gap: 10px;
  ${flex_col};
  @media (max-width: 1100px) {
    width: 100%;
  }
`
export const RightSection = styled.div`
  width: 38%;
  row-gap: 10px;
  ${flex_col};
  @media (max-width: 768px) {
    margin-top: 100px;
    width: 100%;
  }
`
export const Button = styled.button`
  border: none;
  background: none;
  color: ${white};
  cursor: pointer;
  &:disabled {
    cursor: default;
  }
`

export default GlobalStyles
