import {
  Container,
  flex_col,
  grayish,
  graylight,
  ralewayFont,
  text_sm,
  text_xl,
} from '../../../styles/GlobalStyles'
import Transition from '../../../Transition'
import { ProfileBio, Section } from '../../author'
import { t } from 'i18next'
import { PageHeading } from '../../lifestyle'
import { styled } from 'styled-components'
import { BtnToggleSection } from './BtnToggleSection'
import { useMemo, useState } from 'react'
import {
  ActivityLevel,
  calculateMacros,
  CalculationFocus,
  DietType,
  Gender,
  getActivityLevelHint,
  getCalculationFocusHint,
  getPhysiqueHint,
  MacroNutrients,
  Physique,
} from '../../../utils/MacrosCalculatorUtils'
import Text from '../../../components/Text'

interface MacrosCalculatorSelection {
  physique: Physique
  gender: Gender
  dietType: DietType
  calculationFocus: CalculationFocus
  activityLevel: ActivityLevel
  age: number
  height: number
  weight: number
}
export const MacrosCalculator = () => {
  const [selection, setSelection] = useState<MacrosCalculatorSelection>({
    physique: Physique.NORMAL,
    gender: Gender.MEN,
    dietType: DietType.NORMAL,
    activityLevel: ActivityLevel.MODERATELY_ACTIVE,
    age: 18,
    calculationFocus: CalculationFocus.MAINTAIN,
    height: 160,
    weight: 60,
  })

  const handleChangeSelection = (
    type: keyof MacrosCalculatorSelection,
    value: string | number
  ) => {
    setSelection({
      ...selection,
      [type]: value,
    })
  }

  const calculation = useMemo(() => {
    localStorage.setItem(
      'calculateMacros',
      JSON.stringify(
        calculateMacros(
          selection.gender,
          selection.weight,
          selection.height,
          selection.age,
          selection.physique,
          selection.dietType,
          selection.activityLevel,
          selection.calculationFocus
        )
      )
    )
    return calculateMacros(
      selection.gender,
      selection.weight,
      selection.height,
      selection.age,
      selection.physique,
      selection.dietType,
      selection.activityLevel,
      selection.calculationFocus
    )
  }, [selection])

  const isCalculationFocus = useMemo(
    () =>
      calculation.targetCalories !==
      calculation.targetCaloriesByCalculationFocus,
    [calculation]
  )

  return (
    <Transition>
      <MacrosCalculatorContainer>
        <CalculatorForm>
          <PageHeading as={'h2'}>
            {t('tools.macros-calculator.title')}
          </PageHeading>
          <ProfileBio>{t('tools.macros-calculator.subtitle')}</ProfileBio>
          <IdealSection>
            <BtnToggleSection
              value={selection.gender}
              listBtn={Gender}
              activeValue={(value) => handleChangeSelection('gender', value)}
              label={"I'm a"}
            />
            <BtnToggleSection
              value={selection.physique}
              listBtn={Physique}
              activeValue={(value) => handleChangeSelection('physique', value)}
              label={'your physique'}
              type="physique"
            />
            <BtnToggleSection
              value={selection.dietType}
              listBtn={DietType}
              activeValue={(value) => handleChangeSelection('dietType', value)}
              label={'what is your diet type'}
            />
            <BtnToggleSection
              value={selection.calculationFocus}
              listBtn={CalculationFocus}
              activeValue={(value) =>
                handleChangeSelection('calculationFocus', value)
              }
              label={'what is your main weight goal'}
              type="calculationFocus"
            />
            <RangeSection>
              <Label>
                i am <LabelLight>{selection.age}</LabelLight> years young
              </Label>
              <Range
                type="range"
                min="18"
                max="99"
                value={selection.age}
                onChange={(e) => {
                  handleChangeSelection('age', Number(e.target.value))
                }}
              />
            </RangeSection>

            <RangeSection>
              <Label>
                my height: <LabelLight>{selection.height}</LabelLight> cm
              </Label>
              <Range
                type="range"
                min="100"
                max="250"
                value={selection.height}
                onChange={(e) => {
                  handleChangeSelection('height', Number(e.target.value))
                }}
              />
            </RangeSection>

            <RangeSection>
              <Label>
                current weight: <LabelLight>{selection.weight}</LabelLight> kg
              </Label>
              <Range
                type="range"
                min="30"
                max="120"
                value={selection.weight}
                onChange={(e) => {
                  handleChangeSelection('weight', Number(e.target.value))
                }}
              />
            </RangeSection>

            <LevelSection
              value={selection.activityLevel}
              className="level"
              listBtn={ActivityLevel}
              activeValue={(value) =>
                handleChangeSelection('activityLevel', value)
              }
              label={'activity level'}
              type="activityLevel"
            />
          </IdealSection>
        </CalculatorForm>
        <Calculation>
          <PageHeading as={'h3'}>calculation result</PageHeading>
          <ResultRecord>
            <ResultLabel>target calories</ResultLabel>
            <ResultValue>
              {(isCalculationFocus
                ? calculation.targetCaloriesByCalculationFocus
                : calculation.targetCalories
              ).toFixed(2)}
            </ResultValue>
          </ResultRecord>
          <ResultRecord>macros distribution</ResultRecord>
          <ResultRecord>
            <MacrosRecord>
              <ResultValue>
                {(isCalculationFocus
                  ? calculation.macrosDistributionByCalculationFocus[
                      MacroNutrients.PROTEIN
                    ]
                  : calculation.macrosDistribution[MacroNutrients.PROTEIN]
                ).toFixed(2)}{' '}
              </ResultValue>
              <ResultType>protein</ResultType>
            </MacrosRecord>
            <MacrosRecord>
              <ResultValue>
                {(isCalculationFocus
                  ? calculation.macrosDistributionByCalculationFocus[
                      MacroNutrients.CARBOHYDRATES
                    ]
                  : calculation.macrosDistribution[MacroNutrients.CARBOHYDRATES]
                ).toFixed(2)}{' '}
              </ResultValue>
              <ResultType>carbs</ResultType>
            </MacrosRecord>
            <MacrosRecord>
              <ResultValue>
                {(isCalculationFocus
                  ? calculation.macrosDistributionByCalculationFocus[
                      MacroNutrients.FATS
                    ]
                  : calculation.macrosDistribution[MacroNutrients.FATS]
                ).toFixed(2)}{' '}
              </ResultValue>
              <ResultType>fats</ResultType>
            </MacrosRecord>
          </ResultRecord>
          <PageHeading as={'h4'}>description</PageHeading>
          <HintRecord>
            <HintType>activity level</HintType>
            <ResultHint>
              {getActivityLevelHint(selection.activityLevel)}
            </ResultHint>
          </HintRecord>
          <HintRecord>
            <HintType>calculation focus</HintType>
            <ResultHint>
              {getCalculationFocusHint(selection.calculationFocus)}
            </ResultHint>
          </HintRecord>
          <HintRecord>
            <HintType>physique</HintType>
            <ResultHint>
              {getPhysiqueHint(selection.physique).definition}
            </ResultHint>
            <ResultHint>
              {getPhysiqueHint(selection.physique).appearance}
            </ResultHint>
          </HintRecord>
        </Calculation>
      </MacrosCalculatorContainer>
    </Transition>
  )
}

const MacrosCalculatorContainer = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const CalculatorForm = styled(Section)`
  @media (max-width: 768px) {
    width: 100%;
  }
`
const Calculation = styled(Section)`
  width: 38%;
  @media (max-width: 768px) {
    margin-top: 100px;
    width: 100%;
  }
`

const IdealSection = styled.div`
  row-gap: 30px;
  ${flex_col}
`
const Label = styled.label`
  color: ${graylight};
`
const LabelLight = styled(Label)`
  color: white;
  font-weight: 600;
`
const RangeSection = styled.div`
  ${flex_col}
  row-gap: 10px;
`
const Range = styled.input`
  width: 70%;
`
const LevelSection = styled(BtnToggleSection)`
  @media (max-width: 500px) {
    &.level {
      ${flex_col}
    }
  }
`

const ResultRecord = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
`

const MacrosRecord = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const HintRecord = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ResultLabel = styled(Text)`
  width: 50%;
  color: ${graylight};
  letter-spacing: 0.1em;
  line-height: 1.3;
  ${ralewayFont};
  ${text_xl}
`
const ResultType = styled(Text)`
  color: ${graylight};
  letter-spacing: 0.1em;
  line-height: 1.3;
  ${ralewayFont};
  ${text_xl}
`

const ResultValue = styled.p`
  width: 100%;
  color: ${graylight};
  letter-spacing: 0.1em;
  line-height: 1.3;
  ${ralewayFont};
  ${text_xl}
`
const HintType = styled(Text)`
  color: ${grayish};
  letter-spacing: 0.1em;
  line-height: 1.3;
  ${ralewayFont};
  ${text_xl}
`
const ResultHint = styled.p`
  width: 100%;
  margin-top: 10px;
  color: ${graylight};
  letter-spacing: 0.1em;
  line-height: 1.3;
  ${ralewayFont};
  ${text_sm}
`
