import { useState } from 'react'
import { SelectedFood } from '../models/food'
import { number } from '../constants/numberRegex'

const useMealPlans = () => {
  const [listMealPlans, setListMealPlans] = useState<SelectedFood[]>(() => {
    const mealPlans = localStorage.getItem('meal-plans')
    return mealPlans ? JSON.parse(mealPlans) : []
  })
  const addMealPlans = (food?: SelectedFood, servingSize?: string) => {
    if (food && servingSize && number.test(servingSize)) {
      const size = parseFloat(servingSize)
      if (size === food.servingSize) {
        setListMealPlans((prev) => [...prev, food])
      } else {
        const calories = food.calories ? food.calories : 0
        const protein = food.protein ? food.protein : 0
        const carbohydrates = food.carbohydrates ? food.carbohydrates : 0
        const fat = food.fat ? food.fat : 0
        const servingSize = food.servingSize ? food.servingSize : 0
        const newFood: SelectedFood = {
          ...food,
          calories: parseFloat(((calories / servingSize) * size).toFixed(2)),
          protein: parseFloat(((protein / servingSize) * size).toFixed(2)),
          carbohydrates: parseFloat(
            ((carbohydrates / servingSize) * size).toFixed(2)
          ),
          fat: parseFloat(((fat / servingSize) * size).toFixed(2)),
          servingSize: size,
        }

        setListMealPlans((prev) => [...prev, newFood])
      }
    } else {
      alert('No food selected or invalid serving size value')
    }
  }
  const deleteMeal = (index: number) => {
    const currentPlans = [...listMealPlans]
    currentPlans.splice(index, 1)
    setListMealPlans(currentPlans)
  }
  const saveMealPlans = () => {
    localStorage.setItem('meal-plans', JSON.stringify(listMealPlans))
    alert('save successfully')
  }
  return {
    listMealPlans,
    setListMealPlans,
    addMealPlans,
    saveMealPlans,
    deleteMeal,
  }
}
export default useMealPlans
