export const PATH = {
  HOME: '',
  ABOUT: 'about',
  PROJECTS: 'projects',
  PROJECT: 'project/:slug',
  LIFESTYLE: 'lifestyle',
  THOUGHTS: 'thoughts',
  CONTACT: 'contact',
  POST: 'post/:slug',
  SUBCATEGORY: 'subcategory',
  AUTHOR: 'author/:alias',
  PAGE_NOT_FOUND: '*',
  TOOLS: {
    MACROS_CALCULATOR: 'tools/macros-calculator',
    NUTRITIONAL_VALUE: 'tools/nutritional-value',
  },
}
